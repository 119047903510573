import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const HomePage = () => {
  return (
    <>
      <HomeContainer>
        <div className="text">
          <Link to="/portfolio">
            <h1>TAYLOR MOY</h1>
          </Link>
        </div>
      </HomeContainer>
    </>
  );
};

export default HomePage;

export const Head = () => <title>TMOY || Home</title>;

const HomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 80vh;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("/images/philly.jpg");
  text-align: center;

  h1 {
    position: relative;
    font-weight: normal;
    font-size: 70pt;
    color: transparent;
    background-clip: text;
    -webkit-text-stroke: 3px #fff;
    cursor: pointer;
    transition: 0.5s;
    z-index: 5;
  }

  .text:hover,
  h1:hover {
    font-size: 74pt;
    transition: 0.5s;
  }

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
    height: 95vh;
    text-align: left;
    line-height: 1;
    padding-left: 18px;

    .text span {
      font-size: 30pt;
    }
  }
`;
